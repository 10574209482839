import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetOrdersQuery,
  OrderQuerysetOptionsInput,
  OrderWithSamplesFragment,
} from "../generated/graphql";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Tooltip from "@mui/material/Tooltip";
import ListAlt from "@mui/icons-material/ListAlt";
import Label from "@mui/icons-material/Label";
import { useAuthenticated } from "../lib/auth";
import { OrderPaymentStatuses, OrderProcessStatuses } from "../lib/types";
import ShareLocation from "@mui/icons-material/ShareLocation";
import { BackendTableControls, ColumnDefinition, useBackendTable } from "./DataTable/types";
import { formatPrice } from "../lib/testTypes";
import { DataTable } from "./DataTable";

type OrdersTableCardProps = {
  data: GetOrdersQuery | undefined;
  backendTable: BackendTableControls<OrderQuerysetOptionsInput>
};

export const OrdersTableCard: FC<OrdersTableCardProps> = ({ data, backendTable }) => {
  const orders = data?.getOrders;
  const { lab } = useAuthenticated();
  const navigate = useNavigate();

  const columns: ColumnDefinition<OrderWithSamplesFragment>[] = [
    {
      name: "orderDate",
      label: "Ordered",
    },
    {
      name: "paymentStatus",
      label: "Payment Status",
      render: (order: OrderWithSamplesFragment) => {
        const status = OrderPaymentStatuses.find(
          (status) => status.name === order?.paymentStatus
        );
        return status?.label;
      },
    },
    {
      name: "processStatus",
      label: "Process Status",
      render: (order: OrderWithSamplesFragment) => {
        const status = OrderProcessStatuses.find(
          (status) => status.name === order?.processStatus
        );
        return status?.label;
      },
    },
    {
      name: "description",
      label: "Description",
      render: (order: OrderWithSamplesFragment) => {
        const status = OrderProcessStatuses.find(
          (status) => status.name === order?.processStatus
        );
        return status?.description;
      },
    },
    {
      name: "samplesOrdered",
      label: "# of Tests",
    },
    {
      name: "totalPriceInCents",
      label: "Price",
      render: (order: OrderWithSamplesFragment) => {
        return formatPrice(order.totalPriceInCents);
      },
    },
    {
      name: "forms",
      label: "Printable Forms",
      sortable: false,
      render: (order: OrderWithSamplesFragment) => {
        const status = OrderProcessStatuses.find(
          (status) => status.name === order?.processStatus
        );
        return (
          <>
            <Tooltip title="Print Checklist">
              <IconButton
                color="primary"
                onClick={() =>
                  order?.packingUrl ? window.open(order.packingUrl) : null
                }
              >
                <ListAlt />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print Chain of Custody Form">
              <IconButton
                color="primary"
                onClick={() => window.open("/forms/chain-of-custody.pdf")}
              >
                <ShareLocation />
              </IconButton>
            </Tooltip>
            {order?.shipmentToLab && status?.name !== "COMPLETE" ? (
              <Tooltip title="Print Return Label">
                <IconButton
                  color="primary"
                  onClick={() =>
                    order?.labelsUrl ? window.open(order.labelsUrl) : null
                  }
                >
                  <Label />
                </IconButton>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      name: "order",
      label: "Go to Order",
      sortable: false,
      render: (order: OrderWithSamplesFragment) => {
        return (
          <IconButton
            onClick={() => navigate(`/orders/${order.id}`)}
            color="primary"
          >
            <NavigateNext />
          </IconButton>
        );
      },
    },
  ].filter(column => column.name !== 'totalPriceInCents' || lab?.showTestPrices);

  return (
    <Card sx={{ padding: "0 2em 2em", height: "100%", marginBottom: '1em' }}>
      <h1>Orders</h1>
      {orders?.options.paging.totalCount ? (
        <DataTable<OrderWithSamplesFragment>
          data={
            orders?.items.filter((o) => o?.id) as OrderWithSamplesFragment[]
          }
          columns={columns}
          options={{
            backendTable,
            backendPagingResponse: orders?.options.paging,
          }}
          defaultControls={{
            showColumns: columns.map((c) => ({
              name: c.name,
              show: true,
            })),
          }}
        />
      ) : (
        <>
          <h3>You have no past orders.</h3>
          {lab?.checkoutEnabled ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/checkout")}
            >
              Order Now
            </Button>
          ) : (
            <h5>Contact your sales rep to place an order.</h5>
          )}
        </>
      )}
    </Card>
  );
};

export default OrdersTableCard;
